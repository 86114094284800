import logo from './logo.svg';
import './App.css';
import { ReactDOM } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import DashBoardMain from './pages/dashboard/Main';
import ProductStatus from './pages/productStatus/Main';
import ProductSetting from './pages/productSetting/Main';
import ScheduleSetting from './pages/scheduleSetting/Main';
import ScheduleTable from './pages/scheduleTable/Main';
import ContentRepository from './pages/contentRepo/Main';
import AccountManager from './pages/accountManager/Main';
import AccountReg from './pages/accountManager/AccountReg';
import ProductManager from './pages/productManager/Main';
import ProductReg from './pages/productManager/ProductReg';
import SignIn from './pages/login/Main';
import AccountModify from './pages/accountManager/AccountModify';
import ProductModify from './pages/productManager/productModify';
import EmerContentRepository from './pages/contentRepo/EmergencyRepo';

function App() {
  return (
    <>
      <Routes>
        <Route exact path ='/' element={<SignIn/>}/> 
        <Route exact path ='/dashboard' element={<DashBoardMain/>}/> 
        <Route exact path ='/productStatus' element={<ProductStatus/>}/> 
        <Route exact path ='/productSetting' element={<ProductSetting/>}/> 
        <Route exact path ='/scheduleSetting' element={<ScheduleSetting/>}/> 
        <Route exact path ='/scheduleTable' element={<ScheduleTable/>}/> 
        <Route exact path ='/normalContentRepository' element={<ContentRepository/>}/> 
        <Route exact path ='/emerContentRepository' element={<EmerContentRepository/>}/> 
        <Route exact path ='/accountManager' element={<AccountManager/>}/> 
        <Route exact path ='/accountReg' element={<AccountReg/>}/> 
        <Route exact path ='/accountModify/:pk' element={<AccountModify/>}/> 
        <Route exact path ='/productManager' element={<ProductManager/>}/> 
        <Route exact path ='/productReg' element={<ProductReg/>}/> 
        <Route exact path ='/productModify/:pk' element={<ProductModify/>}/> 
      </Routes>
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <h1 className="text-4xl font-bold underline text-rose-300">
    //       Hello world!
    //     </h1>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
