import { useEffect, useRef, useState } from 'react'
import {CalendarIcon,ChartPieIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon,ChevronRightIcon, ChevronLeftIcon} from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { GetConnectJwt } from '../../utils/NetworkUtil'
import { LocalClear } from '../../utils/localStorageUtil'
import { useNavigate } from 'react-router'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ProductStatus() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [prodTable, setProdTable] = useState([])
  const navigate = useNavigate()
  const pageBasePoint = 10 
  const [startPage, setStartPage] = useState(0)
  const [endPage, setEndPage] = useState(pageBasePoint)
  const [page, setPage] = useState(1)
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null)   

  const pages = [
    { name: '제품 설정 메뉴', href: '#', current: false },
    { name: '제품 상태', href: '#', current: true },
  ]

  useEffect(() => {
    dataLoading()
  }, [])

  const dataLoading = async() => {
    const uri = '/devices/getStatusList/'
    const res = await GetConnectJwt(uri)

    if(res.result === true){
      setProdTable(res.data)
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const prevPagination = () => {
    if (page < 2 ){
      return false 
    }
    setStartPage(startPage-pageBasePoint)
    setEndPage(endPage-pageBasePoint)
    setPage(page-1)
  }

  const nextPagiatnion = () => {
    const limit = Math.ceil(prodTable.length / pageBasePoint)
    if (page >= limit){
      return false
    }
    setStartPage(startPage+pageBasePoint)
    setEndPage(endPage+pageBasePoint)
    setPage(page+1)
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-12'>
                  <div className='flex flex-row justify-start'>
                    <span className='text-xl font-semibold text-black self-center'>제품 상태 정보</span>                  
                  </div>
                  <table className="min-w-full divide-y divide-gray-300 mt-4 table-auto">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Product ID
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          현장 명칭 
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          모델명 
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          설치 지역
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          제품 상태 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          운영 상태 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          운영 모드
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          콘텐츠 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          CPU 사용률
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          RAM 사용률
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          사용가능 용량
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                      {prodTable.map((data, idx) => (
                        (idx >= startPage && idx < endPage ? (
                          <tr key={idx} className='hover:bg-slate-50'>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              {data.deviceID}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Operation</dt>
                                <dd className="mt-1 truncate text-gray-700">{data.oper}</dd>
                                <dt className="sr-only sm:hidden">date</dt>
                                <dd className="mt-1 truncate text-gray-500 sm:hidden">{data.date}</dd>
                              </dl>
                            </td>
                            {/* {id: 'AD-1234-1234', alias:'안양시청', model:'2252A', prodStatus: true, operStatus: false, content:'테스트.mp4', cpu:'22.52%', ram:'1.2GB', availaStorage:'10GB'},  */}
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.alias}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.modelName}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.location}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {data.deviceStatus === true ? (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-indigo-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>ON</span>
                              ) : (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-red-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>OFF</span>
                              )}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {data.operStatus === true ? (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-indigo-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>ON</span>
                              ) : (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-red-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>OFF</span>
                              )}
                            </td>
                            <td className={data.operMode === '긴급 모드' ? 'text-red-700 hidden px-3 py-4 text-sm sm:table-cell': data.operMode === '날씨 모드' ? 'text-green-500 hidden px-3 py-4 text-sm sm:table-cell' : data.operMode === '미세먼지 모드' ? 'text-green-500 hidden px-3 py-4 text-sm sm:table-cell' :'text-black hidden px-3 py-4 text-sm sm:table-cell'}> 
                              {data.operMode}
                            </td>
                            {/* <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.operMode}</td> */}
                            <td className="hidden px-3 py-4 text-sm text-black hover:text-indigo-500 hover:underline hover:cursor-pointer sm:table-cell">{data.playContent === null ? '데이터 없음' : data.playContent}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.cpu === null ? '데이터 없음' : data.cpu}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.ram === null ? '데이터 없음' : data.ram}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.storage === null ? '데이터 없음' : data.storage}</td>
                          </tr>
                        ) : null )
                      ))}
                    </tbody>
                  </table>
                  <div className='flex flex-row justify-between pt-2'>
                    <span className='text-gray-500 text-base font-normal self-center'>총 {prodTable.length}개의 제품이 등록되어 있음.</span>
                    <div className='flex flex-row justify-end gap-x-2'>
                      <ChevronLeftIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => prevPagination()} />
                      <span className='rounded-lg bg-gray-100 text-black px-3 py-1 self-center'>{page}</span>
                      <ChevronRightIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => nextPagiatnion()} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}


export default ProductStatus