
export const MailValidation = (email) => {
  if (email.includes('@') === false){
    return false 
  }
  return true 
}

export const PWValiation = (pw) => {
  if (pw.length < 8){
    return false 
  }
  return true 
}

export const NullValidation = (id, name, email, password, password2, adminAuth, userAuth) =>{
  console.log(id) 
  if (id === null || id === '' || id === undefined){
    console.log('id null')
    return {result:false, message:"아이디를 입력해주세요."}
  }
  if (name === null || name === '' || name === undefined){
    return {result:false, message:"사용자 이름을 입력해주세요."}
  }
  if (email === null || email === '' || email === undefined){
    return {result:false, message:"이메일 주소를 입력해주세요."}
  }
  if (password === null || password === '' || password === undefined){
    return {result:false, message:"패스워드를 입력해주세요."}
  }
  if (password2 === null || password2 === '' || password2 === undefined){
    return {result:false, message:"재확인 패스워드를 입력해주세요."}
  }
  if (adminAuth === false && userAuth === false){
    return {result:false, message:"사용자 권한을 설정해주세요."}
  }
  return {result:true}
}