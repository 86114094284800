
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {Bars3Icon,BellIcon,CalendarIcon,ChartPieIcon,Cog6ToothIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon,XMarkIcon,} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import jwtDecode from 'jwt-decode'
import { LocalClear, LocalGet } from '../utils/localStorageUtil'
import { useNavigate } from 'react-router'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MainTop = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const host = process.env.REACT_APP_HOST_ADDR
  const navigate = useNavigate()

  useEffect(() => {
    const access = LocalGet('access')
    const info = jwtDecode(access)
    setUserInfo(info)
  }, [])

  const logout = () => {
    LocalClear() 
    navigate('/')
  }

  const userNavigation = [
    { name: '내 정보 수정하기', href: '#', func: null },
    { name: '로그아웃', href: '#', func: logout },
  ]

  return(
    <div className="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8">
      <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

        <div className="flex flex-row flex-grow justify-end gap-x-4 self-stretch lg:gap-x-6">
          <div className="flex items-center gap-x-4 lg:gap-x-6">

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="sr-only">Open user menu</span>
                {/* <img className="h-10 w-10 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> */}
                <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                  {userInfo.userPic !== null ? (
                    <img className='h-full w-full rounded-full' src={`${host}${userInfo.userPic}`}/> 
                  ) : (
                    <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  )}

                </span>
                <span className="hidden lg:flex lg:items-center">
                  <span className="ml-4 text-base font-semibold leading-6 text-gray-900" aria-hidden="true">
                    {`${userInfo.id}`}
                    {/* //** user name */}
                  </span>
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Menu.Button>
              <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 border border-gray-300 z-10 mt-2.5 w-56 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <div className="px-4 py-3">
                  <p className="truncate text-sm font-medium text-gray-900">{`${userInfo.name}님`}</p>
                    <p className="text-sm">만나서 반갑습니다.</p>
                  </div>
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a href={item.href} onClick={() => item.func()} className={classNames(active ? 'bg-gray-50' : '','block px-3 py-1 text-sm leading-6 text-gray-900')}>
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainTop; 