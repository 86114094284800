import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {Bars3Icon,BellIcon,CalendarIcon,ChartPieIcon,Cog6ToothIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon,XMarkIcon,} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import TotalProduct from '../../assets/images/TotalProduct.png'
import TotalActProd from '../../assets/images/TotalActProd.png'
import TotalInstitute from '../../assets/images/TotalInstitute.png'
import TotlaInactProd from '../../assets/images/TotlaInactProd.png'
import ApexCharts, {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import { PostConnect } from '../../utils/NetworkUtil'
import { useNavigate } from 'react-router'
import { LocalSave } from '../../utils/localStorageUtil'

const SignIn = () => {

  const idRef = useRef(null)
  const passwordRef = useRef(null)
  const navigate = useNavigate()
  const [errMsg, setErrMsg] = useState(null)

  useEffect(() => {
    // TODO: adding the logic that checking jwt token data are in localStorage 
    
  }, [])

  const login = async() => {
    const uri = `/users/signin/`
    const id = idRef.current.value 
    const password = passwordRef.current.value 

    if (id === null || id === undefined || id === ''){
      setErrMsg("아이디를 입력해주세요.")
      return false 
    }

    if (password === null || password === undefined || password === ''){
      setErrMsg("비밀번호를 입력해주세요.")
      return false 
    }
    const data = {id: id, password: password}
    const res = await PostConnect(uri, data) 

    if(res.result === true){
      const {access, refresh} = res.data 
      LocalSave('access', access)
      LocalSave('refresh', refresh)
      navigate('/dashboard')
    }else{
      const response = res.error.response.data 
      const msg = response.message
      setErrMsg(msg)
      return false 
    }
  }

  const EnterControl = (type, keyCode) => {
    if (type === 'id'){
      if (keyCode === 'Enter' || keyCode === 'NumpadEnter'){
        passwordRef.current.focus()
      }
    }

    if (type === 'password'){
      if (keyCode === 'Enter' || keyCode === 'NumpadEnter'){
        login()
      }
    }
  }

  return (
    <div className='h-screen'>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-10 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Bitgeul"/>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                빛글 프로젝션 CMS 시스템
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                계정이 없으신가요? {' '}
                <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  담당자에게 문의해 주세요.
                </a>
              </p>
            </div>

            <div className="mt-10">
              <div>
                <div className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">ID</label>
                    <div className="mt-2">
                      <input id="email" name="email" type="text" className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" ref={idRef} onKeyDown={(key) => EnterControl('id', key.code)} />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Password</label>
                    <div className="mt-2">
                      <input id="password" name="password" type="password" className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" ref={passwordRef} onKeyDown={(key) => EnterControl('password', key.code)} />
                    </div>
                  </div>

                  <span className='text-sm font-semibold text-red-700'>{errMsg === null ? null : errMsg}</span>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input id="remember-me" name="remember-me" type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                      <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                        Remember me
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                        비밀번호를 잃어버리셨나요?
                      </a>
                    </div>
                  </div>

                  <div>
                    <button type="submit" onClick={() => login()} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">로그인</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://assets.website-files.com/603da93843cbc3c11a511875/603f3d796f29300689800dbf_%E2%80%A2%20Bitmap.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default SignIn