import { Fragment, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import {CalendarIcon,ChartPieIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon, CheckCircleIcon, CheckBadgeIcon, ClockIcon, ExclamationTriangleIcon, Cog6ToothIcon, TrashIcon, HeartIcon, XMarkIcon, FaceFrownIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { CheckIcon, ChevronLeftIcon, ChevronRightIcon, PencilIcon, PlusIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition } from '@headlessui/react'
import SuccessNotification from '../../utils/successNotification'
import FailNotiModal from '../../utils/failNotiModal'
import FailNotification from '../../utils/failNotification'
import ProgressModal from '../../utils/progressModal'
import { DeleteConnectJwt, GetConnectJwt, PostConnectJwt } from '../../utils/NetworkUtil'
import { LocalClear } from '../../utils/localStorageUtil'
import { useNavigate } from 'react-router'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SideNav = forwardRef((props, ref) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const [pk, setPK] = useState(null) 
  const [thumb, setThumb] = useState(null) 
  const [filename, setFileName] = useState(null) 
  const [size, setSize] = useState(null) 
  const [type, setType] = useState(null) 
  const [resolution, setResolution] = useState(null) 
  const [content, setContent] = useState(null) 
  const [created, setCreated] = useState(null)
  const navigate = useNavigate()

  const dataControl = (filename, size, type, resolution, created, thumb, content, pk) => {
    setThumb(thumb)
    setFileName(filename)
    setSize(size)
    setType(type)
    setResolution(resolution)
    setContent(content)
    setCreated(created)
    setPK(pk)
  }

  useImperativeHandle(ref, () => ({
    dataControl
  }))

  const contentDelete = async() => {
    const url = `/contents/delete/${pk}/`
    const res = await DeleteConnectJwt(url) 

    if(res.result === true){
      props.dataLoading()
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        alert(msg) 
        return false 
      }
      return false 
    }
  }

  return(
    <div className="sticky top-24 flex-grow w-full">
      <div className="flex flex-col flex-grow w-full">
        <div className="flex max-w-full flex-col w-full shadow-lg">
          <div className="bg-white p-8 border border-gray-300 rounded-lg">
            <div className="space-y-6 pb-4">
              <div>
                <div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
                  <img src={`${host}${thumb}`}  alt="" className="object-cover"/>
                </div>
                <div className="mt-4 flex items-start justify-between">
                  <div>
                    <h2 className="text-base font-semibold leading-6 text-gray-900">
                      <span className="sr-only">Details for </span>{filename}
                    </h2>
                    <p className="text-sm font-medium text-gray-500">{size} MB</p>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="font-medium text-gray-900">Information</h3>
                <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-500">콘텐츠 타입</dt>
                    <dd className="text-gray-900">{type === 'image' ? '이미지' : '비디오'}</dd>
                  </div>
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-500">해상도</dt>
                    <dd className="text-gray-900">{resolution}px</dd>
                  </div>
                  <div className="flex justify-between py-3 text-sm font-medium">
                    <dt className="text-gray-500">업로드 날짜</dt>
                    <dd className="text-gray-900">{created}</dd>
                  </div>
                </dl>
              </div>
              <div className="flex cursor-pointer">
                <a href={`${host}${content}`} className="flex flex-row w-6/12 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 self-center cursor-pointer" download={`${filename}`} >
                  다운로드
                </a>
                <a onClick={() => contentDelete()} className="ml-3 flex flex-row justify-center w-6/12 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 cursor-pointer">
                  콘텐츠 삭제
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

const ContentRepository = () => {
  const host = process.env.REACT_APP_HOST_ADDR
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const progressModalRef = useRef(null) 
  const contentInputRef = useRef(null) 
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const sidenavRef = useRef(null) 
  const navigate = useNavigate()
  const [focus, setFocus] = useState(0) 

  const pages = [
    { name: '스케줄 및 콘텐츠 메뉴', href: '#', current: false },
    { name: '일반 콘텐츠 보관함', href: '#', current: true },
  ]
  const actions = [
    { title: '일반 모드', href: '#', description:`사용자가 지정한 시간에 설정한 콘텐츠를 표출하도록 기능을 수행합니다.\n시간대별로 유연하게 콘텐츠 송출을 관리할 수 있습니다.`, icon: ClockIcon, iconForeground: 'text-black', iconBackground: 'bg-slate-100'},
    { title: '긴급 모드', href: '#', description:'제품의 운영 모드가 긴급 모드인 경우 시간과 관계 없이 긴급 콘텐츠로 지정된 콘텐츠를 송출합니다.\n응급한 상황에 특정 콘텐츠를 송출할때 사용할 수 있습니다.', icon: ExclamationTriangleIcon, iconForeground: 'text-red-700', iconBackground: 'bg-red-100'},
  ]
  const [content, setContent] = useState([])

  useEffect(() => {
    dataLoading()
  }, [])

  useEffect(() => {
    if (content.length !== 0){
      const firstData = content[0]
      const {filename, size, type, resolution, created, thumb, contentUrl, pk} = firstData 
      sidenavRef.current.dataControl(filename, size, type, resolution, created, thumb, contentUrl, pk)
    }
  }, [content])

  const dataLoading = async() => {
    const uri = `/contents/normalList/`
    const res = await GetConnectJwt(uri) 

    if(res.result === true){
      setContent(res.data)
      // TODO: zero filterting 
      setFocus(0)
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const contentUpload = async(e) => {
    const file = e.target.files[0] 
    if (file === undefined || file === null){
      return false
    }
    const fileReader = new FileReader() 
    let base64 

    fileReader.readAsDataURL(file) 
    fileReader.onload = async() => {

      const filename = file.name 
      const type = file.type.split('/')[0]
      const extension = file.type.split('/')[1]
      base64 = fileReader.result 
      const uri = '/contents/uploads/'
      let width, height 

      progressModalRef.current.visibleControl(true) 

      if (type === 'video'){
        const video = document.createElement('video') 
        video.setAttribute('src', fileReader.result )
        video.addEventListener('loadedmetadata', async() => {
          const width = video.videoWidth 
          const height = video.videoHeight
          const resolution = `${width}x${height}`
          const data = { filename: filename, type: type, extension: extension, base64: base64, resolution:resolution}

          const res = await PostConnectJwt(uri, data)

          if(res.result === true){
            progressModalRef.current.visibleControl(false) 
            successNotiRef.current.textEdit("콘텐츠 업로드 성공", "요청하신 콘텐츠를 정상적으로 업로드하였습니다.")
            successNotiRef.current.visibleControl(true) 
            dataLoading()
          }else{
            const response = res.error.response.data 
            const code = response.code 
            const msg = response.message
            progressModalRef.current.visibleControl(false) 
            
            if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
              alert(msg)
              LocalClear()
              navigate('/')
            }else{
              failNotiRef.current.textEdit("운영 모드 변경 실패", msg)
              failNotiRef.current.visibleControl(true)
              return false 
            }
            return false 
          }
          progressModalRef.current.visibleControl(false) 
        })
      }else{
        const image = new Image()
        image.src = fileReader.result
        image.onload = async() => {

          const width = image.width 
          const height = image.height 
          const resolution = `${width}x${height}`
          const data = { filename: filename, type: type, extension: extension, base64: base64, resolution:resolution}

          const res = await PostConnectJwt(uri, data)

          if(res.result === true){
            progressModalRef.current.visibleControl(false) 
            successNotiRef.current.textEdit("콘텐츠 업로드 성공", "요청하신 콘텐츠를 정상적으로 업로드하였습니다.")
            successNotiRef.current.visibleControl(true) 
            dataLoading()
          }else{
            const response = res.error.response.data 
            const code = response.code 
            const msg = response.message
            progressModalRef.current.visibleControl(false) 
            
            if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
              alert(msg)
              LocalClear()
              navigate('/')
            }else{
              failNotiRef.current.textEdit("운영 모드 변경 실패", msg)
              failNotiRef.current.visibleControl(true)
              return false 
            }
            return false 
          }
          progressModalRef.current.visibleControl(false) 
        }
      }
    }
  }

  const uploadBtn = () => {
    contentInputRef.current.click() 
  }

  const sideControl = (idx) => { 
    const target = content[idx]
    const {filename, size, type, resolution, created, thumb, contentUrl, pk} = target 
    sidenavRef.current.dataControl(filename, size, type, resolution, created, thumb, contentUrl, pk)
    setFocus(idx) 
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            <ProgressModal ref={progressModalRef}/> 
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>일반 콘텐츠 보관함</span>
                      <span className='text-base font-normal text-gray-700 self-start'>일반 콘텐츠를 업로드하고 관리할 수 있는 보관함입니다.</span> 
                    </div>
                    <div className='flex flex-row justify-start py-1'>
                      <div className='flex flex-row justify-center rounded-lg bg-indigo-500 py-1.5 px-4 cursor-pointer' onClick={() => uploadBtn()}>
                        <span className='text-white font-semibold self-center'>콘텐츠 업로드</span>
                      </div>
                    </div>
                  </div>

                  {content.length !== 0 ? (
                  <div className='flex flex-row w-full pt-12'>
                    <div className='grid grid-cols-3 gap-4 w-8/12'>
                      {content.map((data, idx) => (
                        <div className='flex flex-col justify-start cursor-pointer' key={data.pk} onClick={() => sideControl(idx)}>
                          <img src={`${host}${data.thumb}`} className={idx === focus ? 'border-2 border-indigo-500 w-48 h-48 rounded-lg' : 'w-48 h-48 rounded-lg'}/> 
                          <span className='text-base font-semibold text-black truncate pr-16'>{data.filename}</span>
                          <span className='text-base font-normal text-gray-700'>{data.size} MB</span>
                        </div>
                      ))}
                    </div>
                    <div className='w-4/12 relative'>
                      <SideNav ref={sidenavRef} dataLoading={dataLoading}/>   
                    </div>
                  </div>
                  ) : (
                    <div className='flex flex-col justify-start pt-12'>
                      <FaceFrownIcon className='w-12 h-12 text-black self-center'></FaceFrownIcon>
                      <span className='text-lg font-semibold text-black self-center pt-2'>업로드된 콘텐츠가 없습니다.</span>
                      <span className='text-lg font-semibold text-black self-center'>콘텐츠를 업로드해주세요.</span>
                    </div> 
                  )}

                  <input type='file' id='contents' accept='.png, .jpg, .jpeg, .mp4' className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hidden' ref={contentInputRef} onChange={(e) => contentUpload(e)}/> 

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ContentRepository