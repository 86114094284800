import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { HomeIcon,ClockIcon, ExclamationTriangleIcon, ClipboardDocumentListIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { ChevronRightIcon, PencilIcon, XMarkIcon, PaperClipIcon, PhotoIcon, UserCircleIcon} from '@heroicons/react/20/solid'
import { GetConnect, PostConnect, PutConnect } from '../../utils/NetworkUtil'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import { useLocation, useNavigate, useParams } from 'react-router'
import { MailValidation, NullValidation, PWValiation } from './Valiation'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const AccountModify = () => {
  const host = process.env.REACT_APP_HOST_ADDR
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userImage, setUserImage] = useState(null)
  const userIDRef = useRef(null) 
  const usernameRef = useRef(null) 
  const userMailRef = useRef(null) 
  const passwordRef = useRef(null) 
  const passwordRef2 = useRef(null) 
  const adminAuthRef = useRef(null) 
  const userAuthRef = useRef(null) 
  const imageInputRef = useRef(null) 
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const navigate = useNavigate()
  const params = useParams() 
  

  useEffect(() => {
    init() 
  }, [])

  const init = async() => {
    const pk = params.pk 
    const uri = `/users/detail/${pk}/`
    const res = await GetConnect(uri) 

    if (res.result === true){
      const userDict = res.data 
      userIDRef.current.value = userDict.id 
      usernameRef.current.value = userDict.name 
      userMailRef.current.value = userDict.email
      if (userDict.auth === 'admin'){
        adminAuthRef.current.checked = true 
      }else{
        userAuthRef.current.checked = true
      }

      if (userDict.profile !== null){

        const imgres = await fetch(`${host}${userDict.profile}`)
        const blob = await imgres.blob() 

        const reader = new FileReader() 
        reader.readAsDataURL(blob)
        reader.onload = () => {
          const base64 = reader.result 
          setUserImage(base64)
        }
      }
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  const pages = [
    { name: '제품 및 계정 메뉴', href: '#', current: false },
    { name: '계정 관리', href: '#', current: true },
  ]
  
  const imageUpload = () => {
    imageInputRef.current.click()
  }

  const imgUploadProc = (e) => {
    const file = e.target.files[0] 
    if (file === undefined || file === null){
      setUserImage(null) 
      return false
    }
    const fileReader = new FileReader() 
    let base64 

    fileReader.readAsDataURL(file) 
    fileReader.onload = () => {
      base64 = fileReader.result 
      setUserImage(base64)
    }
  }

  const modify = async() => {

    const userid = userIDRef.current.value 
    const username = usernameRef.current.value
    const userMail = userMailRef.current.value
    const password = passwordRef.current.value
    const password2 = passwordRef2.current.value
    const adminAuth = adminAuthRef.current.checked
    const userAuth = userAuthRef.current.checked

    const nullValid = NullValidation(userid, username, userMail, password, password2, adminAuth, userAuth) 
    
    if (nullValid.result === false){
      failNotiRef.current.textEdit("회원 정보 수정 실패", `${nullValid.message}`)
      failNotiRef.current.visibleControl(true)
      return false       
    }

    const mailValid = MailValidation(userMail)
    if (mailValid === false){
      failNotiRef.current.textEdit("회원 정보 수정 실패", "올바른 이메일 주소를 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    const pwValid = PWValiation(password)
    if (pwValid === false){
      failNotiRef.current.textEdit("회원 정보 수정 실패", "비밀번호는 8자 이상으로 설정해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    if (password !== password2){
      failNotiRef.current.textEdit("회원 정보 수정 실패", "비밀번호와 재확인 비밀번호가 서로 일치하지 않습니다.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    if (adminAuth === false && userAuth === false){
      failNotiRef.current.textEdit("회원 정보 수정 실패", "사용자 권한을 지정해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }


    const auth = adminAuth === true ? 'admin' : 'user'
    const pk = params.pk 

    const parameters = {id:userid, name:username, password:password,  email:userMail, userAuth: auth, userImage:userImage, pk:pk}
    const uri = '/users/modify/'
    const res = await PutConnect(uri, parameters)
    if (res.result === true){
      successNotiRef.current.textEdit("회원 정보 수정 완료", "회원가입이 정상적으로 완료되었습니다.")
      successNotiRef.current.visibleControl(true)
      navigate('/accountManager')
    }else{
      const response = res.error.response.data 
      console.log(response)
      const msg = response.message
      failNotiRef.current.textEdit("회원 정보 수정 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 
          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>계정 수정</span>
                    </div>
                  </div>
                    
                  <div>
                    <div>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">계정 정보를 입력해주세요.</p>

                      <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">ID<span className='text-red-700'>*</span></label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="아이디를 입력해주세요" ref={userIDRef} />
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            이름<span className='text-red-700'>*</span>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="사용자 이름 또는 닉네임을 입력해주세요." ref={usernameRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            이메일<span className='text-red-700'>*</span>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="email" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="이메일을 입력해주세요." ref={userMailRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-col justify-center'>
                              <div className='flex flex-row justify-start'>비밀번호<span className='text-red-700'>*</span></div>
                              <span className='text-gray-800 font-normal text-sm'>8자 이상의 비밀번호를 설정해주세요.</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0 pt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="password" placeholder='비밀번호를 입력해주세요.' className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" ref={passwordRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            비밀번호 재확인<span className='text-red-700'>*</span>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="password" placeholder='비밀번호를 다시 한번 입력해주세요.' className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" ref={passwordRef2}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6">
                          <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">Photo</label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex items-center gap-x-3">
                              {/* marking  */}
                              {userImage === null ? (
                                <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                              ) : (
                                <img src={userImage} className="h-12 w-12 text-gray-300" aria-hidden="true" />
                              )}
                              <button type="button" className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={() => imageUpload()}>이미지 업로드</button>
                              <input type='file' id='image' accept='.png, .jpg, .jpeg' className='rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hidden' ref={imageInputRef} onChange={(e) => imgUploadProc(e)}/> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='pt-12'>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">사용자 권한 설정</h2>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">생성할 계정에 부여할 권한을 설정해주세요.</p>

                      <div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <fieldset>
                          <legend className="sr-only">Push Notifications</legend>
                          <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
                          <div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">사용자 권한<span className='text-red-700'>*</span></div>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <div className="max-w-lg">
                                <div className="mt-6 space-y-6">
                                  <div className="flex flex-col justify-start">
                                    <div className="flex items-center gap-x-3">
                                      <input id="push-email" name="push-notifications" type="radio" className="self-start mt-1 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" ref={adminAuthRef}/>
                                      <div className='flex flex-col justify-start'>
                                        <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900"> 관리자 권한(Admin)</label>
                                        <p className="mt-1 text-gray-600">해당 권한의 사용자는 계정 관리, 제품 관리 기능을 사용할 수 있으므로 신중하게 설정해주세요.</p>
                                      </div>
                                    </div>
                                  </div> 

                                  <div className="flex flex-col justify-start">
                                    <div className="flex items-center gap-x-3">
                                      <input id="push-email" name="push-notifications" type="radio" className="self-start mt-1 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" ref={userAuthRef}/>
                                      <div className='flex flex-col justify-start'>
                                        <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900"> 사용자 권한(User)</label>
                                        <p className="mt-1 text-gray-600">해당 권한의 사용자는 계정 관리, 제품 관리 기능을 사용할 수 없습니다.</p>
                                      </div>
                                    </div>
                                  </div> 
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => {navigate('/accountManager')}}>취소</button>
                    <button type="submit" className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => modify()}>
                      수정하기
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default AccountModify