import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { HomeIcon,ClockIcon, ExclamationTriangleIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { ChevronRightIcon, PencilIcon, XMarkIcon} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router'
import { DeleteConnect, GetConnect } from '../../utils/NetworkUtil'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import FailModal from '../../utils/failModal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AccountManager = () => {
  const navigate = useNavigate();
  const pages = [ { name: '제품 및 계정 메뉴', href: '#', current: false }, { name: '계정 관리', href: '#', current: true },]
  const host = process.env.REACT_APP_HOST_ADDR

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const deleteModalRef = useRef(null)
  const [userList, setUserList] = useState([])

  useEffect(() => {

  }, [])

  useLayoutEffect(() => {
    dataLoading()
  }, [])

  const dataLoading = async() => {
    const uri = '/users/userList/'
    const res = await GetConnect(uri) 
    if(res.result === true){
      setUserList(res.data)
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  const userDelete = async(pk) => {
    const uri = `/users/userDelete/${pk}/`
    const res = await DeleteConnect(uri)
    if(res.result === true){
      dataLoading() 
      return true 
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  const pageMove = (path) => {
    navigate(path)
  }

  const dataDeleteModal = (pk) => {
    const title = "계정 삭제"
    const text = "한번 삭제된 계정은 다시 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
    deleteModalRef.current.pksetting(pk) 
    deleteModalRef.current.textEdit(title, text)
    deleteModalRef.current.visibleControl(true)
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            <FailModal ref={deleteModalRef} retFunc={userDelete} /> 
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>계정 관리</span>
                      <span className='text-base font-normal text-gray-700 self-start'>계정을 추가하거나 기존 계정을 수정하거나 삭제할 수 있습니다. </span> 
                    </div>
                    <div className='flex flex-row justify-start py-1'>
                      <div className='flex flex-row justify-center rounded-lg bg-indigo-500 py-1.5 px-4 cursor-pointer' onClick={() => pageMove('/accountReg')}>
                        <span className='text-white font-semibold self-center cursor-pointer'>계정 추가하기</span>
                      </div>
                    </div>
                  </div>

                  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 pt-12">
                    {userList.map((person) => (
                      <li key={person.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-lg border border-gray-300">
                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="truncate text-sm font-medium text-gray-900">{`${person.id}`}</h3>
                              {person.auth === 'admin' ? (
                                <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                  {person.auth}
                                </span>
                              ) : (
                                <span className="inline-flex flex-shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-green-600/20">
                                  {person.auth}
                                </span>
                              )}

                            </div>
                            <p className="mt-1 truncate text-sm text-gray-500">{`이름: ${person.name}`}</p>
                            <p className="mt-1 truncate text-sm text-gray-500">{`이메일: ${person.email}`}</p>
                          </div>
                          
                          {person.profile === null ? (
                            <svg className="h-16 w-16 text-gray-300 rounded-full border border-gray-300" fill="currentColor" viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          ) : (
                            <img className="h-16 w-16 flex-shrink-0 rounded-full bg-gray-300" src={`${host}${person.profile}`} alt="" />
                          )}

                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="flex w-0 flex-1">
                              <a href='#' onClick={() => pageMove(`/accountModify/${person.pk}`)} className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                수정하기
                              </a>
                            </div>
                            <div className="-ml-px flex w-0 flex-1">
                              <a href='#' onClick={() => dataDeleteModal(person.pk)} className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-red-700">
                                <XMarkIcon className="h-5 w-5 text-red-700" aria-hidden="true" />
                                삭제하기
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default AccountManager