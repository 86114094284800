import { useEffect, useRef, useState } from 'react'
import {CalendarIcon,ChartPieIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon,ChevronRightIcon, ChevronLeftIcon} from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { GetConnectJwt, PutConnect, PutConnectJwt } from '../../utils/NetworkUtil'
import { LocalClear } from '../../utils/localStorageUtil'
import { useNavigate } from 'react-router'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import useForceUpdate from 'use-force-update'
import FailNotiModal from '../../utils/failNotiModal'
import { LocalGet } from '../../utils/localStorageUtil'
import SystemPowerModal from '../../utils/SystemPowerModal'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ProductSetting() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate() 
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const failNotiModalRef = useRef(null) 
  const pageBasePoint = 10 
  const [startPage, setStartPage] = useState(0)
  const [endPage, setEndPage] = useState(pageBasePoint)
  const [page, setPage] = useState(1)
  const pages = [{ name: '제품 설정 메뉴', href: '#', current: false },{ name: '제품 설정', href: '#', current: true },]
  const [prodTable, setProdTable] = useState([])
  const checkBoxRef = useRef([])
  const forceUpdate = useForceUpdate()
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const host = process.env.REACT_APP_HOST_ADDR
  const rebootModalRef = useRef(null)
  const shutdownModalRef = useRef(null)

  useEffect(() => {
    dataLoading()
  }, []) 

  const dataLoading = async() => {
    const uri = '/devices/getStatusList/'
    const res = await GetConnectJwt(uri)
    if(res.result === true){
      const newData = res.data 
      newData.forEach(element => {
        element.checked = false 
      })
      setProdTable(newData)
      forceUpdate()
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const allSelectControl = (method) =>{
    if (method === 'select'){
      const prodData = prodTable
      prodData.forEach(element => {
        element.checked = true 
      })
      setProdTable(prodData)
      forceUpdate()
    }else{
      const prodData = prodTable
      prodData.forEach(element => {
        element.checked = false 
      })
      setProdTable(prodData)
      forceUpdate()
    }
  }

  const individualControl = (idx) => {
    const prodData = prodTable
    prodData[idx].checked = !prodData[idx].checked
    setProdTable(prodData)
    forceUpdate()
  }

  const modeChange = async(mode) => {
    const checkFilter = prodTable.filter(data => data.checked == true) 
    if (checkFilter.length === 0){
      // ! Not selected 
      failNotiModalRef.current.textEdit("운영 모드 변경 실패", "운영 모드를 변경할 기기를 선택해주세요.")
      failNotiModalRef.current.visibleControl(true) 
      return false 
    }

    const target = new Array() 
    checkFilter.forEach(element=> {
      target.push(element.pk)
    })

    const data = {mode : mode, target: target}
    const uri = `/devices/getStatusList/`
    const res = await PutConnectJwt(uri, data)

    if(res.result === true){
      successNotiRef.current.textEdit("운영 모드 변경 성공", "선택한 기기들의 운영 모드를 정상적으로 변경하였습니다.")
      successNotiRef.current.visibleControl(true) 
      dataLoading()
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("운영 모드 변경 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const systemBootControlModal = (pk) => {
    rebootModalRef.current.pksetting(pk)
    rebootModalRef.current.textEdit("시스템 재부팅", "시스템 재부팅을 실행하시겠습니까?")
    rebootModalRef.current.visibleControl(true)
  }

  const shutdownModal = (pk) => {
    shutdownModalRef.current.pksetting(pk)
    shutdownModalRef.current.textEdit("시스템 종료", "시스템을 종료하시겠습니까?")
    shutdownModalRef.current.visibleControl(true)
  }

  const systemBootControl = async(mode, pk) => {
    const target = [pk]
    const data = {mode : mode, target: target}
    const uri = `/devices/getStatusList/`
    const res = await PutConnectJwt(uri, data)

    if(res.result === true){
      successNotiRef.current.textEdit("운영 모드 변경 성공", "선택한 기기의 시스템 부딩 설정을 정상적으로 변경하였습니다.")
      successNotiRef.current.visibleControl(true) 
      dataLoading()
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("운영 모드 변경 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const prevPagination = () => {
    if (page < 2 ){
      return false 
    }
    setStartPage(startPage-pageBasePoint)
    setEndPage(endPage-pageBasePoint)
    setPage(page-1)
  }

  const nextPagiatnion = () => {
    const limit = Math.ceil(prodTable.length / pageBasePoint)
    if (page >= limit){
      return false
    }
    setStartPage(startPage+pageBasePoint)
    setEndPage(endPage+pageBasePoint)
    setPage(page+1)
  }

  // marking 
  const keystoneControl = (pk) => {
    const topLocation = windowHeight/4 
    const leftLocation = windowWidth/2
    const spec = `height=853,width=512,left=${leftLocation},top=${topLocation}`
    const token = LocalGet('access')
    const uri = `${host}/keystone/control/${pk}/${token}/`
    window.open(uri, '_blank', spec, false)
  }

  const reboot = async(pk) =>{
    await systemBootControl('reboot', pk)
  }

  const shutdown = async(pk) => {
    await systemBootControl('shutdown', pk)
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 
          
          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            <FailNotiModal ref={failNotiModalRef} /> 
            <SystemPowerModal ref={rebootModalRef} retFunc={reboot} /> 
            <SystemPowerModal ref={shutdownModalRef} retFunc={shutdown} /> 


            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-col justify-start'>
                    <span className='text-xl font-semibold text-black self-start'>제품 설정</span>
                    <span className='text-base font-normal text-gray-700 self-start'>제품의 운영 모드를 설정하거나 시스템 재부팅/종료를 수행할 수 있습니다.</span> 
                  </div>
                  <div className='flex flex-col rounded-lg bg-indigo-100 mt-8 p-6'>
                    <span className='text-lg font-semibold text-black self-start'>운영 모드 설정</span>

                    <div className='flex flex-row flex-grow-0 justify-between pt-4 gap-x-4'>
                      <div className='flex flex-col justify-start' style={{width: '22%'}}>
                        <span className='text-base font-normal text-gray-700 self-start'>일반 콘텐츠 송출 모드</span>
                        <div className='flex flex-row justify-center rounded-lg w-full bg-gray-700 py-1.5 cursor-pointer mt-1' onClick={() => modeChange('normal')}>
                          <span className='text-base font-normal text-white self-start'>일반 모드 설정</span>
                        </div>
                      </div>

                      <div className='flex flex-col justify-start' style={{width: '22%'}}>
                        <span className='text-base font-normal text-gray-700 self-start'>긴급 콘텐츠 송출 모드</span>
                        <div className='flex flex-row justify-center rounded-lg w-full bg-red-700 py-1.5 cursor-pointer mt-1' onClick={() => modeChange('emergency')}>
                          <span className='text-base font-normal text-white self-start'>긴급 모드 설정</span>
                        </div>
                      </div>

                      <div className='flex flex-col justify-start' style={{width: '22%'}}>
                        <span className='text-base font-normal text-gray-700 self-start'>날씨 송출 모드</span>
                        <div className='flex flex-row justify-center rounded-lg w-full bg-indigo-700 py-1.5 cursor-pointer mt-1' onClick={() => modeChange('weather')}>
                          <span className='text-base font-normal text-white self-start'>날씨 모드 설정</span>
                        </div>
                      </div>

                      <div className='flex flex-col justify-start' style={{width: '22%'}}>
                        <span className='text-base font-normal text-gray-700 self-start'>온도 송출 모드</span>
                        <div className='flex flex-row justify-center rounded-lg w-full bg-green-700 py-1.5 cursor-pointer mt-1' onClick={() => modeChange('temperature')}>
                          <span className='text-base font-normal text-white self-start'>온도 모드 설정</span>
                        </div>
                      </div>

                      <div className='flex flex-col justify-start' style={{width: '22%'}}>
                        <span className='text-base font-normal text-gray-700 self-start'>미세먼지 송출 모드</span>
                        <div className='flex flex-row justify-center rounded-lg w-full bg-pink-700 py-1.5 cursor-pointer mt-1' onClick={() => modeChange('finedust')}>
                          <span className='text-base font-normal text-white self-start'>미세먼지 모드 설정</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className='flex flex-row justify-end gap-x-4 pt-4'>
                    <div className='flex flex-row justify-center bg-indigo-700 rounded-lg px-4 py-1.5 cursor-pointer' onClick={() => allSelectControl('select')}>
                      <span className='text-white text-base font-semibold'>전체 선택</span>
                    </div>
                    <div className='flex flex-row justify-center bg-gray-700 rounded-lg px-4 py-1.5 cursor-pointer' onClick={() => allSelectControl('unselect')}>
                    <span className='text-white text-base font-semibold'>전체 선택 해제</span>
                    </div>
                    
                  </div>
                  <table className="min-w-full divide-y divide-gray-300 mt-4 table-auto">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          #
                        </th>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                          Product ID
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          현장 명칭 
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                          모델명 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          제품 상태 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          운영 상태 
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          운영 모드
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          키스톤 제어
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          시스템 재부팅
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          시스템 종료
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white ">
                      {prodTable.map((data, idx) => (
                        (idx >= startPage && idx < endPage ? (
                          <tr key={data.pk} className='hover:bg-slate-50'>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              <input id={`${idx}`} name={`${idx}`} type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" checked={data.checked === true ? true : false} onChange={() => individualControl(idx)} ref={(ref) => checkBoxRef.current[idx] = ref} />
                              {/* checked={data.checked === true ? true : false} */}
                            </td>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              {data.deviceID}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Operation</dt>
                                <dd className="mt-1 truncate text-gray-700">{data.oper}</dd>
                                <dt className="sr-only sm:hidden">date</dt>
                                <dd className="mt-1 truncate text-gray-500 sm:hidden">{data.date}</dd>
                              </dl>
                            </td>
                            {/* {id: 'AD-1234-1234', alias:'안양시청', model:'2252A', prodStatus: true, operStatus: false, content:'테스트.mp4', cpu:'22.52%', ram:'1.2GB', availaStorage:'10GB'},  */}
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.alias}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.modelName}</td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {data.deviceStatus === true ? (<span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-indigo-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>ON</span>
                              ) : (<span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-red-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>OFF</span>)}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {data.operStatus === true ? (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-indigo-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>ON</span>
                              ) : (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800"><svg className="h-1.5 w-1.5 fill-red-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>OFF</span>
                              )}
                            </td>
                            <td className={data.operMode === '긴급 모드' ? 'text-red-700 hidden px-3 py-4 text-sm sm:table-cell'
                              : data.operMode === '날씨 모드' ? 'text-green-700 hidden px-3 py-4 text-sm sm:table-cell' : data.operMode === '미세먼지 모드' ? 'text-pink-700 hidden px-3 py-4 text-sm sm:table-cell' :'text-black hidden px-3 py-4 text-sm sm:table-cell'}> 
                              {data.operMode}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                              <button type="button" className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-slate-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border border-black" onClick={() => keystoneControl(data.pk)} >
                                키스톤 제어
                              </button>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                              <button type="button" className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => systemBootControlModal(data.pk)} >
                                시스템 재부팅 
                              </button>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                              <button type="button" className="rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  onClick={() => shutdownModal(data.pk)} >
                                시스템 종료  
                              </button>
                            </td>
                          </tr>
                        ) : null ) 
                      ))}
                    </tbody>
                  </table>
                  <div className='flex flex-row justify-between pt-2'>
                    <span className='text-gray-500 text-base font-normal'>총 {prodTable.length}개의 제품이 등록되어 있음.</span>
                    <div className='flex flex-row justify-end gap-x-2'>
                      <ChevronLeftIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => prevPagination()} />
                      <span className='rounded-lg bg-gray-100 text-black px-3 py-1 self-center'>{page}</span>
                      <ChevronRightIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => nextPagiatnion()} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}


export default ProductSetting