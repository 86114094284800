import axios from "axios"
import { LocalClear, LocalGet } from "./localStorageUtil"
import { useNavigate } from "react-router"

export const GetConnect = async(uri) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  try{
    const res = await axios.get(requri)
    const resData = res.data.data 
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const GetConnectJwt = async(uri) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  const userToken = LocalGet('access')
  const headers = { headers : {Authorization:`Bearer ${userToken}`}, }

  try{
    const res = await axios.get(requri, headers)
    const resData = res.data.data 
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const PostConnect = async(uri, params) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  try{
    const res = await axios.post(requri, params)
    const resData = res.data.data
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const PostConnectJwt = async(uri, params) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  const userToken = LocalGet('access')
  const headers = { headers : {Authorization:`Bearer ${userToken}`}, }

  try{
    const res = await axios.post(requri, params, headers)
    const resData = res.data.data
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const PutConnect = async(uri, params) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  try{
    const res = await axios.put(requri, params)
    const resData = res.data.data
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const PutConnectJwt = async(uri, data) => {
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  const userToken = LocalGet('access')
  const headers = { headers : {Authorization:`Bearer ${userToken}`}, }

  try{
    const res = await axios.put(requri, data, headers)
    const resData = res.data.data 
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const DeleteConnect = async(uri) =>{
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  try{
    const res = await axios.delete(requri)
    const resData = res.data.data
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

export const DeleteConnectJwt = async(uri) =>{
  const host = process.env.REACT_APP_HOST_ADDR
  const requri = `${host}${uri}`
  const userToken = LocalGet('access')
  const headers = { headers : {Authorization:`Bearer ${userToken}`}, }
  
  try{
    const res = await axios.delete(requri, headers)
    const resData = res.data.data
    if (resData === null || resData === undefined || resData === ''){
      return {result: true}
    }else{
      return {result: true, data:resData}
    }
  }catch(e){
    return {result: false, error:e}
  }
}

