import { useEffect, useRef, useState } from 'react'
import { HomeIcon,ClockIcon, ExclamationTriangleIcon, Cog8ToothIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { ChevronRightIcon, PencilIcon, XMarkIcon, UserCircleIcon, CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import { useNavigate, useParams } from 'react-router'
import { Combobox } from '@headlessui/react'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import { GetConnect, GetConnectJwt, PostConnect, PutConnect } from '../../utils/NetworkUtil'
import moment from 'moment'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProductModify = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userList, setUserList] = useState([])
  const [query, setQuery] = useState('')
  const [selectedPerson, setSelectedPerson] = useState(null)
  const deviceIDRef = useRef(null) 
  const aliasRef = useRef(null) 
  const modelRef = useRef(null) 
  const locationRef = useRef(null) 
  const setupDateRef = useRef(null) 
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const navigate = useNavigate();
  const params = useParams() 
  const todayDate = moment().format('YYYY-MM-DD')

  const pages = [
    { name: '제품 및 계정 메뉴', href: '#', current: false },
    { name: '제품 관리', href: '/productManager', current: true },
  ]

  useEffect(() => {
    getUserInfo()
  }, [])

  useEffect(() => {
    if (userList.length !== 0){
      getDeivceInfo()
    }
  }, [userList])

  const pageMove = (path) => {
    navigate(path)
  }

  const getUserInfo = async() => {
    const tempUserList = new Array() 
    const uri = '/users/userList/'
    const res = await GetConnectJwt(uri) 
    if(res.result === true){
      const auth = res.data.auth 
      res.data.forEach((element, idx) => {
        const tempDict = {pk:element.pk, id:element.id, email:element.email, auth:element.auth}
        tempUserList.push(tempDict)
      })
      setUserList(tempUserList)
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  const getDeivceInfo = async() => {
    const pk = params.pk
    const uri = `/devices/getDetail/${pk}/`
    const res = await GetConnectJwt(uri) 
    if(res.result === true){
      let userListIdx = null  
      deviceIDRef.current.value = res.data.deviceID 
      aliasRef.current.value = res.data.alias 
      modelRef.current.value = res.data.modelName
      locationRef.current.value = res.data.location
      setupDateRef.current.value = res.data.setupDate
      let userPK = res.data.userPK
      userList.forEach((element,idx) => {
        if (element.pk === userPK){
          userListIdx = idx 
          return false 
        }
      })
      setSelectedPerson(userList[userListIdx])
      deviceIDRef.current.focus()
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }
  
  const registrationSubmit = async() => {
    const deviceID = deviceIDRef.current.value 
    const alias = aliasRef.current.value 
    const model = modelRef.current.value 
    const location = locationRef.current.value 
    const setupDate = setupDateRef.current.value 

    // ! user input checking 
    if (deviceID === '' || deviceID === null || deviceID === undefined){
      deviceIDRef.current.focus()
      failNotiRef.current.textEdit("제품 등록 실패", "제품 ID를 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }

    if (alias === '' || alias === null || alias === undefined){
      aliasRef.current.focus()
      failNotiRef.current.textEdit("제품 등록 실패", "제품 현장명칭(별칭)을 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }

    if (model === '' || model === null || model === undefined){
      modelRef.current.focus()
      failNotiRef.current.textEdit("제품 등록 실패", "모델명을 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }

    if (location === '' || location === null || location === undefined){
      modelRef.current.focus()
      failNotiRef.current.textEdit("제품 등록 실패", "설치 지역을 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }

    if (setupDate === '' || setupDate === null || setupDate === undefined){
      modelRef.current.focus()
      failNotiRef.current.textEdit("제품 등록 실패", "설치 일자를 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }

    if (selectedPerson === null){
      failNotiRef.current.textEdit("제품 등록 실패", "사용자를 지정해주세요.")
      failNotiRef.current.visibleControl(true)
      return false
    }
    // ! user input checking end 

    const userPK = selectedPerson.pk 
    const priorPK = params.pk 
    const uri = '/devices/registration/'
    const data = { deviceID : deviceID, alias : alias, modelName : model, user : userPK, priorPK:priorPK, location:location, setupDate: setupDate}
    const res = await PutConnect(uri, data) 

    if(res.result === true){
      navigate('/productManager')
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("제품 수정 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  // const filteredPeople = query === '' ? people : people.filter((person) => {return person.name.toLowerCase().includes(query.toLowerCase())})

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>제품 정보 수정</span>
                    </div>
                  </div>
                    
                  <div>
                    <div>
                      <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">제품 정보 수정을 위해 필요한 정보를 입력해주세요.</p>

                      <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>제품 ID</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="제품을 식별하기 위한 ID를 입력해주세요." ref={deviceIDRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>현장명칭(별칭)</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="제품을 쉽게 식별하기 위해 현장명칭(별칭)을 입력해주세요." ref={aliasRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>모델명</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="제품 모델명을 입력해주세요." ref={modelRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>설치 지역</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="text" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" placeholder="제품의 설치 지역을 입력해주세요." ref={locationRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>설치 일자</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input type="date" className="block flex-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 pl-3" defaultValue={todayDate} ref={setupDateRef}/>
                            </div>
                          </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 self-center">
                            <div className='flex flex-row justify-start'>
                              <span className='text-sm font-medium text-black'>사용자</span>
                              <span className='text-sm font-medium text-red-700'>*</span>
                            </div>
                          </label>
                          <div className="mt-2 sm:col-span-2 sm:mt-0">
                            {/* <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md"> */}
                              <Combobox as="div" value={selectedPerson} onChange={setSelectedPerson}>
                                <div className="relative mt-2 sm:max-w-md">
                                  <Combobox.Input className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={(event) => setQuery(event.target.value)} displayValue={(userList) => userList?.id}/>
                                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </Combobox.Button>

                                  {userList.length > 0 && (
                                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                      {userList.map((data, idx) => (
                                        <Combobox.Option key={idx} value={data} className={({ active }) => classNames( 'relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900')}>
                                          {({ active, selected }) => (
                                            <>
                                              <div className="flex">
                                                <span className={classNames('truncate', selected && 'font-semibold')}>{data.id}</span>
                                                <span className={classNames('ml-2 truncate text-gray-500',active ? 'text-indigo-200' : 'text-gray-500')}>
                                                  {`${data.email} / ${data.auth}`}
                                                </span>
                                              </div>

                                              {selected && (
                                                <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4',active ? 'text-white' : 'text-indigo-600')}>
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </Combobox.Option>
                                      ))}
                                    </Combobox.Options>
                                  )}
                                </div>
                              </Combobox>
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => pageMove('/productManager')}>취소</button>
                    <button type="submit" className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={() => registrationSubmit()}>제품 수정</button>
                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ProductModify