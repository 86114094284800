import { useState } from 'react'
import {CalendarIcon,HomeIcon,InboxStackIcon, CpuChipIcon, CalendarDaysIcon, UsersIcon,DocumentMagnifyingGlassIcon, Cog8ToothIcon} from '@heroicons/react/24/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const teams = [
  { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
  { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
  { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
]

const DesktopSideNavigation = () => {
  const locHref = window.location.href.replace('https://','').replace('http://','').split('/')[1]
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: locHref.indexOf('dashboard') !== -1 ? true : false,},
  ]
  
  const prodNavigation = [
    { name: '제품 상태', href: '/productStatus', icon: DocumentMagnifyingGlassIcon, current: locHref.indexOf('productStatus') !== -1 ? true : false,},
    { name: '제품 설정', href: '/productSetting', icon: Cog8ToothIcon, current: locHref.indexOf('productSetting') !== -1 ? true : false,},
  ]
  
  const scheduleNavigation = [
    { name: '콘텐츠 스케줄 설정', href: '/scheduleSetting', icon: CalendarIcon, current: locHref.indexOf('scheduleSetting') !== -1 ? true : false,},
    { name: '스케줄 테이블', href: '/scheduleTable', icon: CalendarDaysIcon, current: locHref.indexOf('scheduleTable') !== -1 ? true : false,},
    { name: '일반 콘텐츠 보관함', href: '/normalContentRepository', icon: InboxStackIcon, current: locHref.indexOf('normalContentRepository') !== -1 ? true : false,},
    { name: '긴급 콘텐츠 보관함', href: '/emerContentRepository', icon: InboxStackIcon, current: locHref.indexOf('emerContentRepository') !== -1 ? true : false,},
  ]
  
  const ManageNavigation = [
    { name: '계정 관리', href: '/accountManager', icon: UsersIcon, current: locHref.indexOf('accountManager') !== -1 ? true : false,},
    { name: '제품 관리', href: '/productManager', icon: CpuChipIcon, current: locHref.indexOf('productManager') !== -1 ? true : false,},
  ]

  return(
    // {/* //** Desktop sidebar */}
    // {/* Static sidebar for desktop */}
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4 ">
        <div className="flex h-16 shrink-0 items-center">
          <img
            className="h-8 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-2">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className={classNames(item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                      <item.icon className={classNames( item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0' )} aria-hidden="true" />
                      {item.name}
                    </a>
                  </li>
                  )
                )}
              </ul>
            </li>

            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">제품 설정 메뉴</div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {prodNavigation.map((data, idx) => (
                  <li key={data.name}>
                    <a href={data.href} className={classNames(data.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                      <data.icon className={classNames( data.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0' )} aria-hidden="true" />
                      {data.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">스케줄 및 콘텐츠 메뉴</div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {scheduleNavigation.map((data, idx) => (
                  <li key={data.name}>
                    <a href={data.href} className={classNames(data.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                      <data.icon className={classNames( data.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0' )} aria-hidden="true" />
                      {data.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>

            <li>
              <div className="text-xs font-semibold leading-6 text-gray-400">제품 및 계정 메뉴</div>
              <ul role="list" className="-mx-2 mt-2 space-y-1">
                {ManageNavigation.map((data, idx) => (
                  <li key={data.name}>
                    <a href={data.href} className={classNames(data.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}>
                      <data.icon className={classNames( data.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0' )} aria-hidden="true" />
                      {data.name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            
            {/* <li className="mt-auto">
              <a
                href="#"
                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
              >
                <Cog6ToothIcon
                  className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                  aria-hidden="true"
                />
                Settings
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </div>   
  )
}

export default DesktopSideNavigation