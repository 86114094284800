import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {Bars3Icon,BellIcon,CalendarIcon,ChartPieIcon,ClipboardDocumentListIcon,Cog6ToothIcon,DocumentDuplicateIcon,FolderIcon,HomeIcon,UsersIcon,XMarkIcon,} from '@heroicons/react/24/outline'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import TotalProduct from '../../assets/images/TotalProduct.png'
import TotalActProd from '../../assets/images/TotalActProd.png'
import TotalInstitute from '../../assets/images/TotalInstitute.png'
import TotlaInactProd from '../../assets/images/TotlaInactProd.png'
import ApexCharts, {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import { GetConnectJwt } from '../../utils/NetworkUtil'
import { useNavigate } from 'react-router'
import { LocalClear } from '../../utils/localStorageUtil'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const ProdCategoryChart = (props) => {
  const {dataListKey, dataListCount} = props 
  let series; 
  let labels; 
  if (dataListKey === null && dataListCount === null){
    series = [1]
    labels = ['데이터 없음']
  }else{
    series = dataListCount 
    labels = dataListKey
  }

  const options = { 
    chart:{
      type: "donut",
      width:340, 
    }, 
    labels: labels, 
    legend: { show: true,  position: 'right',  horizontalAlign: 'center', fontSize : '14px',  
      itemMargin:{
        vertical: 10, 
        horizontal: 10, 
      }, 
      offsetY: 0, 
    }, 
    responsive: [{ breakpoint: 340, 
      options: {
        chart: {
          width: 340
        },
        legend: { show: true,  position: 'bottom',  horizontalAlign: 'center', fontSize : '24px',  
          itemMargin:{
            vertical: 5, 
            horizontal: 10, 
          }, 
          offsetY: 0, 
        }
      }
    }]
  }
  
  return(
    <div className='flex flex-col justify-center w-full h-full'>
      <Chart options={options} series={series} type="donut" width={340} />
    </div>
  )  
}

export const RegionProdChart = (props) => {
  const {dataListKey, dataListCount} = props 

  let series; 
  let labels; 
  if (dataListKey === null && dataListCount === null){
    series = [1]
    labels = ['데이터 없음']
  }else{
    series = dataListCount 
    labels = dataListKey
  }

  const options = { 
    chart:{
      type: "donut",
      width:340, 
    }, 
    labels: labels,
    legend: { show: true,  position: 'right',  horizontalAlign: 'center', fontSize : '14px',  
      itemMargin:{
        vertical: 10, 
        horizontal: 10, 
      }, 
      offsetY: 0, 
    }, 
    responsive: [{ breakpoint: 340, 
      options: {
        chart: {
          width: 340
        },
        legend: { show: true,  position: 'bottom',  horizontalAlign: 'center', fontSize : '24px',  
          itemMargin:{
            vertical: 5, 
            horizontal: 10, 
          }, 
          offsetY: 0, 
        }
      }
    }]
  }
  
  return(
    <div className='flex flex-col justify-center w-full h-full'>
      <Chart options={options} series={series} type="donut" width={340} />
    </div>
  )
}

export const UserChart = (props) => {
  const {totalUserCount, userCount} = props
  let series = [1, 0]
  if (totalUserCount !== null || userCount !== null){
    series = [totalUserCount-userCount, userCount]
  }
  const options = { 
    chart:{ type: "pie", width:340,}, 
    labels: ['관리자', '사용자'],
    legend: { show: true,  position: 'right',  horizontalAlign: 'center', fontSize : '14px',  
      itemMargin:{ vertical: 10,  horizontal: 10, }, 
      offsetY: 0, 
    }, 
    responsive: [{ breakpoint: 340, 
      options: {
        chart: {width: 340},
        legend: { show: true,  position: 'bottom',  horizontalAlign: 'center', fontSize : '24px',  
          itemMargin:{ vertical: 5,  horizontal: 10, }, 
          offsetY: 0, 
        }
      }
    }]
  };

  return(
    <div className='flex flex-col justify-center w-full h-full'>
      <Chart options={options} series={series} type="pie" width={340} />
    </div>
  )
}

export default function DashBoardMain() {
  const pageBasePoint = 5
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [generalData, setGeneralData] = useState([])
  const [totalUser, setTotalUser] = useState(null)
  const [userCount, setUserCount] = useState(null) 
  const [locationKey, setLocationKey] = useState(null) 
  const [locationCount, setLocationCount] = useState(null)
  const [modelKey, setModelKey] = useState(null) 
  const [modelCount, setModelCount] = useState(null)
  const [prodTable, setProdTable] = useState([])
  const [startPage, setStartPage] = useState(0)
  const [endPage, setEndPage] = useState(pageBasePoint)
  const [page, setPage] = useState(1)
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const navigate = useNavigate()

  useLayoutEffect(() => {
    dashboardDataInit()
  }, [])

  const dashboardDataInit = async() =>{
    const uri = `/init/dashboard/init/`
    const res = await GetConnectJwt(uri)

    if(res.result === true){
      console.log(res) 
      setGeneralData([
        {name: '총 제품 수', count : `${res.data.totalDev.toLocaleString()}개`, icon: TotalProduct, rating: null, class:'w-5 h-5' }, 
        {name: '등록된 사용자', count : `${res.data.totalUser.toLocaleString()}명`, icon: TotalInstitute, rating: null, class:'w-5 h-4' }, 
        {name: '현재 작동중인 제품 수', count : `${res.data.operDev.toLocaleString()}개`, icon: TotalActProd, rating: null, class:'w-6 h-6' }, 
        {name: '현재 작동하지 않는 제품 수', count : `${res.data.notOperDev.toLocaleString()}개`, icon: TotlaInactProd, rating: null, class:'w-6 h-6' }, 
      ])
      setTotalUser(res.data.totalUser)
      setUserCount(res.data.userCount)

      // * 지역별 설치 제품 수 
      if (res.data.devCountPerLoc !== null){
        const keyList = Object.keys(res.data.devCountPerLoc)
        const tempList = new Array()
        keyList.forEach(element=>{tempList.push(res.data.devCountPerLoc[element])})
        setLocationKey(keyList)
        setLocationCount(tempList)
      }

      // * 모델별 제품 수 
      const ModelKeyList = Object.keys(res.data.devModelCount) 
      const modelTempList = new Array() 
      ModelKeyList.forEach(element=>{modelTempList.push(res.data.devModelCount[element])})
      setModelKey(ModelKeyList)
      setModelCount(modelTempList)

      // * 제품 운영 현황 테이블 데이터 
      setProdTable(res.data.devTableData)
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const prevPagination = () => {
    if (page < 2 ){
      return false 
    }
    setStartPage(startPage-pageBasePoint)
    setEndPage(endPage-pageBasePoint)
    setPage(page-1)
  }

  const nextPagiatnion = () => {
    const limit = Math.ceil(prodTable.length / pageBasePoint)
    if (page >= limit){
      return false
    }
    setStartPage(startPage+pageBasePoint)
    setEndPage(endPage+pageBasePoint)
    setPage(page+1)
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0'>
                {/* //** Dashboard General report table  */}
                <div className='flex flex-row flex-grow'>
                  <span className='text-xl font-semibold text-black'>General Report</span>
                </div>

                <div className='flex flex-row justify-between pt-4'>
                  {generalData.map((data, idx) => (
                    <div className='flex flex-col bg-white shadow-lg shadow-gray-300 p-4 gap-y-4 rounded-lg border border-gray-200' style={{width:'22.5%'}} key={idx}>
                      <div className='flex flex-row justify-between'>
                        <img src={data.icon} className={`${data.class} self-center`} /> 
                        {data.rating === null ? null : (
                          <span className='text-base font-semibold text-black self-center'>{data.rating}</span>
                        )}
                      </div>
                      <div className='flex flex-row justify-start'>
                        <span className='text-2xl font-semibold text-black'>{data.count}</span>
                      </div>
                      <div className='flex flex-row justify-start'>
                        <span className='text-base font-normal text-gray-500'>{data.name}</span>
                      </div>
                    </div>
                  ))}
                </div> 
                {/* //** Dashboard General report table end */}

                {/* //** Chart Area  */}
                <div className='flex flex-row flex-grow justify-between pt-12'>
                  <div className='flex flex-col gap-y-4 w-full' style={{width:'30%'}}>
                    <span className='text-xl font-normal text-black pb-4'>관리자/유저 비율</span>
                    <div className='flex flex-row border-gray-200 border rounded-lg shadow-lg shadow-gray-300 py-6 h-72'>
                      {/* marking  */}
                      <UserChart totalUserCount={totalUser} userCount={userCount}/> 
                    </div>
                  </div>

                  <div className='flex flex-col gap-y-4 w-full' style={{width:'30%'}}>
                    <span className='text-xl font-normal text-black pb-4'>지역별 설치 제품 수</span>
                    <div className='flex flex-row border-gray-200 border rounded-lg shadow-lg shadow-gray-300 py-6 h-72'>
                      <RegionProdChart dataListKey={locationKey} dataListCount={locationCount}/> 
                    </div>
                  </div>

                  <div className='flex flex-col gap-y-4 w-full' style={{width:'30%'}}>
                    <span className='text-xl font-normal text-black pb-4'>모델별 제품 수</span>
                    <div className='flex flex-row border-gray-200 border rounded-lg shadow-lg shadow-gray-300 py-6 h-72'>
                      <ProdCategoryChart dataListKey={modelKey} dataListCount={modelCount} /> 
                    </div>
                  </div>
                </div>
                {/* //** Chart Area end */}

                {/* //** Product Table */}
                <div className='flex flex-col flex-grow justify-start pt-12'>
                  <span className='text-xl font-semibold text-black pb-4'>제품 운영 현황</span>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"> Product ID</th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"> 운영상태 </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"> 상태 업데이트 날짜 </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"> 상태 업데이트 시간 </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {prodTable.map((data, idx) => (
                        (idx >= startPage && idx < endPage ? (
                          <tr key={idx}>
                            <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              {data.deviceID}
                              <dl className="font-normal lg:hidden">
                                <dt className="sr-only">Operation</dt>
                                <dd className="mt-1 truncate text-gray-700">{data.oper}</dd>
                                <dt className="sr-only sm:hidden">date</dt>
                                <dd className="mt-1 truncate text-gray-500 sm:hidden">{data.date}</dd>
                              </dl>
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                              {data.operStatus === true ? (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800">
                                  <svg className="h-1.5 w-1.5 fill-indigo-400" viewBox="0 0 6 6" aria-hidden="true"> <circle cx={3} cy={3} r={3} /> </svg>
                                  운영중
                                </span>
                              ) : (
                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-black ring-1 ring-inset ring-gray-800">
                                <svg className="h-1.5 w-1.5 fill-red-400" viewBox="0 0 6 6" aria-hidden="true">
                                  <circle cx={3} cy={3} r={3} />
                                </svg>
                                전원 꺼짐
                              </span>
                              )}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{data.updateDate === null ? "데이터 없음" : data.updateDate}</td>
                            <td className="px-3 py-4 text-sm text-gray-500">{data.updateTime === null ? "데이터 없음" : data.updateTime}</td>
                          </tr>
                        ) : null)
                      ))}
                    </tbody>
                  </table>
                  <div className='flex flex-row justify-between pt-2'>
                    <span className='text-gray-500 text-base font-normal self-center'>총 {prodTable.length}개의 제품이 등록되어 있음.</span>
                    <div className='flex flex-row justify-end gap-x-2'>
                      <ChevronLeftIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => prevPagination()} />
                      <span className='rounded-lg bg-gray-100 text-black px-3 py-1 self-center'>{page}</span>
                      <ChevronRightIcon className="h-6 w-6 flex-shrink-0 text-gray-400 self-center cursor-pointer" aria-hidden="true" onClick={() => nextPagiatnion()} />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
