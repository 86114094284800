import { useEffect, useRef, useState } from 'react'
import {HomeIcon,ChevronRightIcon, CheckCircleIcon, ClockIcon, ExclamationTriangleIcon, Cog6ToothIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { CheckIcon } from '@heroicons/react/20/solid'
import { GetConnectJwt, PostConnectJwt } from '../../utils/NetworkUtil'
import { LocalClear } from '../../utils/localStorageUtil'
import { useNavigate } from 'react-router'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import useForceUpdate from 'use-force-update'
import moment from 'moment'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ScheduleSetting = () => {
  const host = process.env.REACT_APP_HOST_ADDR
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [filterID, setFilterID] = useState(null) 
  const [selectedMode, setMode] = useState('normal')
  const failNotiRef = useRef(null) 
  const successNotiRef = useRef(null) 
  const scheduleNameRef = useRef(null)
  const startDateRef = useRef(null)
  const endDateRef = useRef(null) 
  const startTimeRef = useRef(null)
  const endTimeRef = useRef(null) 
  const navigate = useNavigate()
  const forceUpdate = useForceUpdate()
  const todayDate = new moment().format('YYYY-MM-DD')
  const todayTime = new moment().format('HH:MM')
  const afterDate = new moment().add(1, 'M').format('YYYY-MM-DD')
  const afterTime = new moment().add(16, 'hours').format('HH:MM')

  const pages = [
    { name: '스케줄 및 콘텐츠 메뉴', href: '#', current: false },
    { name: '콘텐츠 스케줄 설정', href: '#', current: true },
  ]
  const actions = [
    { title: '일반 모드', href: '#', mode:'normal',  description:`사용자가 지정한 시간에 설정한 콘텐츠를 표출하도록 기능을 수행합니다.\n시간대별로 유연하게 콘텐츠 송출을 관리할 수 있습니다.`, icon: ClockIcon, iconForeground: 'text-black', iconBackground: 'bg-slate-100'},
    { title: '긴급 모드', href: '#', mode:'emergency', description:'제품의 운영 모드가 긴급 모드인 경우 시간과 관계 없이 긴급 콘텐츠로 지정된 콘텐츠를 송출합니다.\n응급한 상황에 특정 콘텐츠를 송출할때 사용할 수 있습니다.', icon: ExclamationTriangleIcon, iconForeground: 'text-red-700', iconBackground: 'bg-red-100'},
  ]

  const [prodTable, setProdTable] = useState([])
  const [content, setContent] = useState([])

  useEffect(() => {
    deviceLoading()
  }, [])

  useEffect(() => {
    if (selectedMode === 'normal'){
      contentLoading() 
    }else if(selectedMode === 'emergency'){
      emerContentLoading()
    }
  }, [selectedMode])

  const deviceLoading = async() => {
    const uri = `/devices/getList/`
    const res = await GetConnectJwt(uri)

    if(res.result === true){
      const deviceData = res.data 
      deviceData.forEach(element=>{
        element.checked = false
      }, [])
      setProdTable(deviceData)

    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  } 

  const contentLoading = async() => {
    const uri = `/contents/normalList/`
    const res = await GetConnectJwt(uri) 

    if(res.result === true){
      const contentData = res.data 
      contentData.forEach(element=>{
        element.checked = false
      }, [])
      setContent(contentData)
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const emerContentLoading = async() => {
    const uri = `/contents/emergencyList/`
    const res = await GetConnectJwt(uri) 

    if(res.result === true){
      const contentData = res.data 
      contentData.forEach(element=>{
        element.checked = false
      }, [])
      setContent(contentData)
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  const modeChange = (mode) => {
    setMode(mode) 
  }

  const deviceCheck = (idx) =>{
    const deviceData = prodTable
    if (deviceData[idx].checked === true){
      deviceData[idx].checked = false 
    }else{
      deviceData[idx].checked = true 
    }
    setProdTable(deviceData)
    forceUpdate()
  }

  const contentCheck = (idx) =>{
    const contentData = content
    if (contentData[idx].checked === true){
      contentData[idx].checked = false 
    }else{
      contentData[idx].checked = true 
    } 
    setContent(contentData)
    forceUpdate()
  }

  const scheduleSubmit = () => {
    if (selectedMode === 'normal'){
      normalScheduleSubmit()
    }else{
      emergencySchSubmit()
    }
  }

  const normalScheduleSubmit = async() =>{ 
    const scheduleName = scheduleNameRef.current.value
    const startDate = startDateRef.current.value
    const endDate = endDateRef.current.value
    const startTime = startTimeRef.current.value
    const endTime = endTimeRef.current.value
    // ! Input data validation 
    if (scheduleName === '' || scheduleName === undefined || scheduleName === null){
      failNotiRef.current.textEdit("스케줄 등록 실패", "스케줄명을 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }
    if (startDate === '' || startDate === undefined || startDate === null){
      failNotiRef.current.textEdit("스케줄 등록 실패", "시작 날짜를 정확히 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }
    if (endDate === '' || endDate === undefined || endDate === null){
      failNotiRef.current.textEdit("스케줄 등록 실패", "종료 날짜를 정확히 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }
    if (startTime === '' || startTime === undefined || startTime === null){
      failNotiRef.current.textEdit("스케줄 등록 실패", "시작 시간을 정확히 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }
    if (endTime === '' || endTime === undefined || endTime === null){
      failNotiRef.current.textEdit("스케줄 등록 실패", "종료 시간을 정확히 입력해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }
    // ! Input data validation end 

    const deviceList = prodTable.filter(data => data.checked === true)
    const contentList = content.filter(data => data.checked === true)
    
    if (deviceList.length === 0){
      failNotiRef.current.textEdit("스케줄 등록 실패", "콘텐츠 송출 스케줄을 등록할 기기를 선택해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    if (contentList.length === 0){
      failNotiRef.current.textEdit("스케줄 등록 실패", "콘텐츠 송출 스케줄을 등록할 콘텐츠를 선택해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    const uri = `/schedule/registration/`
    const data = { scheduleName : scheduleName,  startDate : startDate,  endDate : endDate,  startTime : startTime,  endTime : endTime,  deviceList : deviceList.map(data => data.pk),  contentList: contentList.map(data=>data.pk), }

    const res = await PostConnectJwt(uri, data)
    if(res.result === true){
      scheduleNameRef.current.value = '' 
      const tempDeviceList = prodTable 
      const tempContentList = contentList 
      tempDeviceList.forEach(element => element.checked = false)
      tempContentList.forEach(element => element.checked = false)
      setProdTable(tempDeviceList)
      setContent(tempContentList)
      forceUpdate() 
      // marking 
      successNotiRef.current.textEdit("스케줄 등록 성공", "스케줄을 정상적으로 등록하였습니다.")
      successNotiRef.current.visibleControl(true) 
      contentLoading()
      return true 
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("스케줄 등록 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
    }
  }

  const emergencySchSubmit = async() =>{
    const deviceList = prodTable.filter(data => data.checked === true)
    const contentList = content.filter(data => data.checked === true)
    
    if (deviceList.length === 0){
      failNotiRef.current.textEdit("긴급 스케줄 등록 실패", "콘텐츠 송출 스케줄을 등록할 기기를 선택해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    if (contentList.length === 0){
      failNotiRef.current.textEdit("긴급 스케줄 등록 실패", "콘텐츠 송출 스케줄을 등록할 콘텐츠를 선택해주세요.")
      failNotiRef.current.visibleControl(true)
      return false 
    }

    const uri = `/schedule/registration/emergency/`
    const data = { deviceList : deviceList.map(data => data.pk),  contentList: contentList.map(data=>data.pk), }

    const res = await PostConnectJwt(uri, data)
    if(res.result === true){
      const tempDeviceList = prodTable 
      const tempContentList = contentList 
      tempDeviceList.forEach(element => element.checked = false)
      tempContentList.forEach(element => element.checked = false)
      setProdTable(tempDeviceList)
      setContent(tempContentList)
      forceUpdate() 
      successNotiRef.current.textEdit("긴급 스케줄 등록 성공", "긴급 스케줄을 정상적으로 등록하였습니다.")
      successNotiRef.current.visibleControl(true) 
      return true 
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("긴급 스케줄 등록 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
    }

  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>콘텐츠 스케줄 설정</span>
                      <span className='text-base font-normal text-gray-700 self-start'>일반/긴급 모드에 따른 제품의 콘텐츠 송출 스케줄을 지정합니다.</span> 
                    </div>
                    <div className='flex flex-row justify-center bg-indigo-500 rounded-lg py-0.5 px-4 cursor-pointer' onClick={() => scheduleSubmit()}>
                      <span className='text-lg font-bold self-center text-white'>스케줄 등록</span>
                    </div>
                  </div>

                  <span className='text-lg font-semibold text-black self-start pt-8'>스케줄 유형 선택</span>

                  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow-lg sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0 mt-4 border border-gray-300">
                    {actions.map((action, actionIdx) => (
                      <div key={action.title} onClick={() => modeChange(action.mode) } className={classNames(actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',actionIdx === 1 ? 'sm:rounded-tr-lg' : '', actionIdx === 0 && selectedMode === 'normal' ? 'ring-inset ring-indigo-500 ring-2' : '', actionIdx === 1 && selectedMode === 'emergency' ? 'ring-inset ring-indigo-500 ring-2' : '', 'group relative bg-white p-6 ')}>
                        <div>
                          <span className={classNames(action.iconBackground,action.iconForeground,'inline-flex rounded-lg p-3 ring-4 ring-white')}>
                            <action.icon className="h-6 w-6" aria-hidden="true" />
                          </span>
                        </div>
                        <div className="mt-8">
                          <h3 className="text-base font-semibold leading-6 text-gray-900">
                            <a href='#' className="focus:outline-none">
                              {/* Extend touch target to entire panel */}
                              <span className="absolute inset-0" aria-hidden="true" />{action.title}
                            </a>
                          </h3>
                          <span className="mt-2 text-sm text-gray-500 whitespace-pre-line">{action.description}</span>
                        </div>
                        <span className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400" aria-hidden="true">
                          <CheckCircleIcon className={classNames(actionIdx === 0 && selectedMode === 'normal' ? 'text-indigo-700' : '', actionIdx === 1 && selectedMode === 'emergency' ? 'text-indigo-700' : '', 'w-7 h-7 text-gray-300' )}/> 
                        </span>
                      </div>
                    ))}
                  </div>

                  {selectedMode === 'normal' ? (
                    <div className='flex flex-col rounded-lg bg-indigo-100 mt-8 p-6'>
                      <span className='text-lg font-semibold text-black self-start'>콘텐츠 스케줄 설정</span>

                      <div className='flex flex-col justify-start pt-4 gap-y-2'>
                      <span className='text-base font-normal text-gray-700 self-start'>스케줄명</span>
                        <input type='text' className='border-0 text-base font-semibold bg-white py-2 w-6/12 rounded-lg' placeholder='스케줄 이름을 지어주세요.' ref={scheduleNameRef} ></input>
                      </div> 

                      <div className='flex flex-row flex-grow-0 justify-between pt-4 gap-x-4'>
                        <div className='flex flex-col justify-start' style={{width: '22%'}}>
                          <span className='text-base font-normal text-gray-700 self-start'>시작 날짜</span>
                          <div className='flex flex-row justify-center rounded-lg w-full bg-white py-1.5 cursor-pointer mt-1'>
                            <input type='date' className='border-0 text-base font-semibold' defaultValue={todayDate} ref={startDateRef}/> 
                            {/* <label for="startDate" className='cursor-pointer'>시작 날짜를 선택해주세요.</label> */}
                          </div>
                        </div>

                        <div className='flex flex-col justify-start' style={{width: '22%'}}>
                          <span className='text-base font-normal text-gray-700 self-start'>시작 시간</span>
                          <div className='flex flex-row justify-center rounded-lg w-full bg-white py-1.5 cursor-pointer mt-1'>
                            <input type='time' className='border-0 text-base font-semibold w-36 self-center' defaultValue={todayTime} ref={startTimeRef}/> 
                          </div>
                        </div>

                        <div className='flex flex-col justify-start' style={{width: '22%'}}>
                          <span className='text-base font-normal text-gray-700 self-start'>종료 날짜</span>
                          <div className='flex flex-row justify-center rounded-lg w-full bg-white py-1.5 cursor-pointer mt-1'>
                          <input type='date' className='border-0 text-base font-semibold' defaultValue={afterDate} ref={endDateRef}/> 
                          </div>
                        </div>

                        <div className='flex flex-col justify-start' style={{width: '22%'}}>
                          <span className='text-base font-normal text-gray-700 self-start'>종료 시간</span>
                          <div className='flex flex-row justify-center rounded-lg w-full bg-white py-1.5 cursor-pointer mt-1'>
                            <input type='time' className='border-0 text-base font-semibold w-36 self-center' defaultValue={afterTime} ref={endTimeRef}/> 
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null }


                  <div className='flex flex-row flex-grow w-full pt-8'>
                    <div className='flex flex-row w-1/2 justify-start'>
                      <div className='flex flex-col flex-grow justify-start' style={{height:'600px'}}>
                        <div className='flex flex-row justify-between pr-14'>
                          <span className='text-lg font-semibold self-center'>제품 선택</span>
                          <div className='flex flex-row justify-start border border-gray-400 rounded-lg w-72 h-12'>
                            <MagnifyingGlassIcon className='w-7 h-7 self-center ml-2 text-gray-600 font-semibold'/> 
                            {/* marking  */}
                            <input className='rounded-lg pl-2 text-base font-semibold flex flex-row justify-start w-full border-none focus:ring-transparent outline-none' 
                              placeholder='검색할 제품 ID를 입력해주세요.'
                              onInput={(obj) => setFilterID(obj.target.value)}
                            /> 
                          </div>
                        </div>

                        <div className='flex flex-col flex-grow h-full w-full overflow-y-auto gap-y-4 pt-4 px-2'>
                          {prodTable.map((data, idx) => (
                            filterID === null || filterID === '' ? (
                              <div className='flex flex-row justify-start gap-x-4 border border-gray-300 rounded-lg bg-white shadow-lg p-6 w-11/12 cursor-pointer' key={idx} onClick={() => deviceCheck(idx)}>
                                <div className='flex flex-row px-2 bg-indigo-100 rounded-lg'>
                                  <Cog6ToothIcon className='w-8 h-8 text-indigo-700 self-center'/> 
                                </div>
                                <div className='flex flex-row flex-grow justify-between'>
                                  <div className='flex flex-col justify-start'>
                                    <span className='text-base font-semibold'>제품 ID: {data.deviceID}</span>
                                    <span className='text-base font-semibold'>현장명칭: {data.alias}</span>
                                  </div> 
                                  <div className={classNames(data.checked === true ? 'flex flex-col justify-center self-center' : 'hidden')}>
                                    <CheckIcon className='text-indigo-700 w-8 h-8 font-bold'/> 
                                  </div> 
                                </div>
                            </div>
                            ) : (
                              data.deviceID.toUpperCase().indexOf(filterID.toUpperCase()) !== -1 ? (
                              <div className='flex flex-row justify-start gap-x-4 border border-gray-300 rounded-lg bg-white shadow-lg p-6 w-11/12 cursor-pointer' key={idx} onClick={() => deviceCheck(idx)}>
                                <div className='flex flex-row px-2 bg-indigo-100 rounded-lg'>
                                  <Cog6ToothIcon className='w-8 h-8 text-indigo-700 self-center'/> 
                                </div>
                                <div className='flex flex-row flex-grow justify-between'>
                                  <div className='flex flex-col justify-start'>
                                    <span className='text-base font-semibold'>제품 ID: {data.deviceID}</span>
                                    <span className='text-base font-semibold'>현장명칭: {data.alias}</span>
                                  </div> 
                                  <div className={classNames(data.checked === true ? 'flex flex-col justify-center self-center' : 'hidden')}>
                                    <CheckIcon className='text-indigo-700 w-8 h-8 font-bold'/> 
                                  </div> 
                                </div>
                              </div>
                              ) : null 
                            )
                          ))}
                        </div>
                      </div>

                    </div>
                    <div className='flex flex-row w-1/2 justify-start'>
                      <div className='flex flex-col flex-grow justify-start' style={{height:'600px'}}>
                    
                        <span className='text-lg font-semibold pb-4'>콘텐츠 선택</span>  

                        <div className='flex flex-col flex-grow h-full w-full overflow-y-scroll gap-y-4 pt-4 px-2'>
                          <div className='grid grid-cols-3 gap-4'>
                            {content.map((data, idx) => ( 
                              <div className='flex flex-col justify-start' onClick={() => contentCheck(idx)} key={idx}>
                                <div className='flex flex-col justify-start cursor-pointer relative'>
                                  {/* //** background */}
                                  {data.checked === true ? ( 
                                    <>
                                      <div className='rounded-lg bg-indigo-500 absolute w-full h-full opacity-75 flex flex-col justify-center'/> 
                                        <div className='rounded-lg absolute w-full h-full flex flex-col justify-center'> 
                                          <CheckIcon className='w-8 h-8 text-white self-center font-bold'/> 
                                          <span className='text-base font-bold self-center text-white'>{`${idx+1}번째 콘텐츠 선택됨`}</span>
                                      </div> 
                                    </>
                                  ) : null }

                                  
                                  <img src={`${host}${data.thumb}`} className='w-48 h-48 rounded-lg'/> 
                                </div>
                                <div className='flex flex-row justify-center'>
                                  <span className='text-base font-semibold text-black self-center truncate'>{data.filename}</span>
                                </div> 
                              </div> 
                            ))}
                          </div>

                        </div>
                      </div>  
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ScheduleSetting