

export const LocalSave = (key, value) => {
  localStorage.setItem(key, value)
  return true 
}

export const LocalGet = (key) => {
  const data = localStorage.getItem(key)
  return data 
}

export const LocalClear = () =>{
  localStorage.clear() 
}