import { useEffect, useRef, useState } from 'react'
import { HomeIcon,ClockIcon, ExclamationTriangleIcon, Cog8ToothIcon, FaceFrownIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { ChevronRightIcon, PencilIcon, XMarkIcon} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router'
import SuccessNotification from '../../utils/successNotification'
import FailNotification from '../../utils/failNotification'
import FailModal from '../../utils/failModal'
import { DeleteConnect, DeleteConnectWithParams, GetConnect, GetConnectJwt } from '../../utils/NetworkUtil'
import FailNotiModal from '../../utils/failNotiModal'
// import Delaunator from 'delaunator'
// import { Homography } from 'homography'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ProductManager = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [deviceList, setDeviceList] = useState([])
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const deleteModalRef = useRef(null)

  const pages = [
    { name: '제품 및 계정 메뉴', href: '#', current: false },
    { name: '제품 관리', href: '#', current: true },
  ]

  useEffect(() => {
    getDeviceList()
  }, [])

  const getDeviceList = async() => {
    const uri ='/devices/getList/'
    const res = await GetConnectJwt(uri) 
    if(res.result === true){
      setDeviceList(res.data)
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  const pageMove = (path) => {
    navigate(path)
  }

  const dataDeleteModal = (pk) => {
    const title = "제품 삭제"
    const text = "한번 삭제된 제품 정보는 다시 복구할 수 없습니다.\n정말 삭제하시겠습니까?"
    deleteModalRef.current.pksetting(pk) 
    deleteModalRef.current.textEdit(title, text)
    deleteModalRef.current.visibleControl(true)
  }

  const userDelete = async(pk) => {
    const uri = `/devices/delete/${pk}/`
    const res = await DeleteConnect(uri)
    if(res.result === true){
      getDeviceList()
      return true 
    }else{
      const response = res.error.response.data 
      const msg = response.message
      failNotiRef.current.textEdit("데이터 로드 실패", msg)
      failNotiRef.current.visibleControl(true)
      return false 
    }
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 
            <FailModal ref={deleteModalRef} retFunc={userDelete} />            

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-row justify-between'>
                    <div className='flex flex-col justify-start'>
                      <span className='text-xl font-semibold text-black self-start'>제품 관리</span>
                      <span className='text-base font-normal text-gray-700 self-start'>제품을 추가하거나 기존 제품 정보를 수정할 수 있습니다.</span> 
                    </div>
                    <div className='flex flex-row justify-start py-1'>
                      <div className='flex flex-row justify-center rounded-lg bg-indigo-500 py-1.5 px-4 cursor-pointer' onClick={() => pageMove('/productReg')}>
                        <span className='text-white font-semibold self-center cursor-pointer'>제품 추가하기</span>
                      </div>
                    </div>
                  </div>

                  {deviceList.length === 0 ? (
                    <div className='flex flex-col justify-start pt-24 gap-y-4'>
                      <FaceFrownIcon className='w-24 h-24 self-center text-black'></FaceFrownIcon>
                      <span className='text-xl font-semibold text-black self-center'>등록된 제품이 없습니다.</span>                      
                    </div> 
                      ) : null }

                  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 pt-12">
                    {deviceList.map((data, idx) => (
                      <li key={idx} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-lg border border-gray-300">
                        <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className='flex flex-col justify-start'>
                            <div className='flex flex-row justify-start gap-x-2'>
                              <div className='flex flex-col justify-center bg-indigo-100 rounded-lg px-2 py-2'>
                                <Cog8ToothIcon className='w-8 h-8 text-indigo-700 font-bold self-center'/> 
                              </div>
                              <div className='flex flex-col justify-center'>
                                <span className='text-base font-bold text-black self-start'>{data.deviceID}</span>
                                <span className='text-base font-semibold text-gray-500 self-start'>{data.alias}</span>
                              </div>
                            </div>
                            <span className='text-base font-normal text-gray-500 self-start pt-2'>모델명: {data.modelName}</span>
                            <span className='text-base font-normal text-gray-500 self-start'>제품 사용자: {data.userName}</span>
                            <span className='text-base font-normal text-gray-500 self-start'>등록일: {data.regDate}</span>
                          </div>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="flex w-0 flex-1">
                              <a href='#' onClick={() => {pageMove(`/productModify/${data.pk}`)}} className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                                <PencilIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                수정하기
                              </a>
                            </div>
                            <div className="-ml-px flex w-0 flex-1">
                              <a href='#' onClick={() => dataDeleteModal(`${data.pk}`)} className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-red-700">
                                <XMarkIcon className="h-5 w-5 text-red-700" aria-hidden="true" />
                                삭제하기
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ProductManager