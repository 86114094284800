import { useEffect, useRef, useState } from 'react'
import {HomeIcon,Cog6ToothIcon, TrashIcon, } from '@heroicons/react/24/outline'
import MainTop from '../../mixins/Top'
import MobileSideNavigation from '../../mixins/mobile/mobileSideNavigation'
import DesktopSideNavigation from '../../mixins/desktop/desktopSideNavigation'
import { ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/20/solid'
import { DeleteConnectJwt, GetConnectJwt } from '../../utils/NetworkUtil'
import FailNotification from '../../utils/failNotification'
import SuccessNotification from '../../utils/successNotification'
import { LocalClear } from '../../utils/localStorageUtil'
import { useNavigate } from 'react-router'
import useForceUpdate from 'use-force-update'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ScheduleTable = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [keyList, setKeyList] = useState([])
  const successNotiRef = useRef(null) 
  const failNotiRef = useRef(null) 
  const navigate = useNavigate()
  const forceUpdate = useForceUpdate()
  const [pageDict, setPageDict] = useState([])
  const pageBasePoint = 5
  const [devList, setDevList] = useState([]) 
  const pages = [
    { name: '스케줄 및 콘텐츠 메뉴', href: '#', current: false },
    { name: '스케줄 테이블', href: '#', current: true },
  ]

  const [scheduleTable, setSchedule] = useState({})

  useEffect(() => {
    dataLoading()
  }, [])

  const dataLoading = async() =>{
    const uri = `/schedule/list/`
    const res = await GetConnectJwt(uri) 

    console.log(res)

    if(res.result === true){
      const devSchedule = res.data.devScheduleDict
      setDevList(res.data.devMetaInfo)

      const FK = res.data.devMetaInfo.map(data => data.devicePK)
      const set = new Set(FK)
      const uniqueDevFK = [...set]
      setKeyList(uniqueDevFK)

      const listPerDev = {}

      const tempDict = {}
      for(let i = 0; i < uniqueDevFK.length; i++){
        const tempArray = new Array()
        const schList = res.data.devScheduleDict.filter(data => data.devicePK === uniqueDevFK[i])
        schList.forEach(Element=>{
          tempArray.push(Element)
        })
        listPerDev[uniqueDevFK[i]] = tempArray
        tempDict[`${uniqueDevFK[i]}_startPage`] = 0
        tempDict[`${uniqueDevFK[i]}_endPage`] = pageBasePoint
        tempDict[`${uniqueDevFK[i]}_page`] = 1
      }

      setPageDict(tempDict)
      setSchedule(listPerDev)

      console.log('listperDev')
      console.log(listPerDev)


    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }

    // if(res.result === true){
    //   const FK = res.data.map(data => data.devicePK)
    //   const set = new Set(FK)
    //   const uniqueDevFK = [...set]
    //   setKeyList(uniqueDevFK)
    //   const listPerDev = {}

    //   const tempDict = {}
    //   for(let i = 0; i < uniqueDevFK.length; i++){
    //     const tempArray = new Array()
    //     const schList = res.data.filter(data => data.devicePK === uniqueDevFK[i])
    //     schList.forEach(Element=>{
    //       tempArray.push(Element)
    //     })
    //     listPerDev[uniqueDevFK[i]] = tempArray
    //     tempDict[`${uniqueDevFK[i]}_startPage`] = 0
    //     tempDict[`${uniqueDevFK[i]}_endPage`] = pageBasePoint
    //     tempDict[`${uniqueDevFK[i]}_page`] = 1
    //   }

    //   setPageDict(tempDict)
    //   setSchedule(listPerDev)
    // }else{
    //   const response = res.error.response.data 
    //   const code = response.code 
    //   const msg = response.message
    //   if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
    //     alert(msg)
    //     LocalClear()
    //     navigate('/')
    //   }else{
    //     failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
    //     failNotiRef.current.visibleControl(true)
    //     return false 
    //   }
    //   return false 
    // }
  }

  const prevPagination = (idx) => {
    const copyData = pageDict
    const page = pageDict[`${idx}_page`]
    const startPage = pageDict[`${idx}_startPage`]
    const endPage = pageDict[`${idx}_endPage`]

    if (page < 2 ){
      return false 
    }

    const setStartPage = startPage-pageBasePoint 
    const setEndPage = endPage - pageBasePoint 
    const setPage = page-1 

    copyData[`${idx}_startPage`] = setStartPage 
    copyData[`${idx}_endPage`] = setEndPage
    copyData[`${idx}_page`] = setPage

    setPageDict(copyData)
    forceUpdate()
  }

  const nextPagiatnion = (idx) => {
    console.log('ok')
    const copyData = pageDict
    const lenCount = scheduleTable[idx].length
    const limit = Math.ceil(lenCount / pageBasePoint)

    const page = pageDict[`${idx}_page`]
    const startPage = pageDict[`${idx}_startPage`]
    const endPage = pageDict[`${idx}_endPage`]

    // const limit = Math.ceil(prodTable.length / pageBasePoint)
    if (page >= limit){
      return false
    }

    const setStartPage = startPage+pageBasePoint
    const setEndPage = endPage+pageBasePoint
    const setPage = page+1

    copyData[`${idx}_startPage`] = setStartPage 
    copyData[`${idx}_endPage`] = setEndPage
    copyData[`${idx}_page`] = setPage
    setPageDict(copyData)
    forceUpdate()
  }

  const scheduleDelete = async(schedulePK, type) => {
    const uri = `/schedule/delete/${schedulePK}/${type}/`
    const res = await DeleteConnectJwt(uri) 

    if(res.result === true){
      await dataLoading()
      return true 
    }else{
      const response = res.error.response.data 
      const code = response.code 
      const msg = response.message
      if (code === 'INVALID_TOKEN' || code === 'UNKNOWN_USER' || code === 'TOKEN_EXPIRED'){
        alert(msg)
        LocalClear()
        navigate('/')
      }else{
        failNotiRef.current.textEdit("콘텐츠 데이터 로드 실패", msg)
        failNotiRef.current.visibleControl(true)
        return false 
      }
      return false 
    }
  }

  return (
    <>
      {/* // ** 전체 Container  */}
      <div className=''>
        <MobileSideNavigation /> 
        <DesktopSideNavigation/> 

        {/* //** Main Top  */}
        <div className="lg:pl-72">
          <MainTop/> 

          <main className="py-10">
            <SuccessNotification ref={successNotiRef}/> 
            <FailNotification ref={failNotiRef}/> 

            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className='flex flex-col flex-grow-0 justify-start'>
                {/* //** BreadCrumbs part */}
                <nav className="flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div>
                        <a href="/dashboard" className="text-gray-400 hover:text-gray-500">
                          <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                          <span className="sr-only">Home</span>
                        </a>
                      </div>
                    </li>
                    {pages.map((page) => (
                      <li key={page.name}>
                        <div className="flex items-center">
                          <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <a href={page.href} className={classNames(page.current ? 'text-indigo-600 hover:text-indigo-700' : 'text-gray-500 hover:text-gray-700', "ml-4 text-sm font-medium")} aria-current={page.current ? 'page' : undefined}>
                            {page.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ol>
                </nav>
                {/* //** BreadCrumbs part end */}

                {/* //** Headline */}
                <div className='flex flex-col flex-grow justify-start pt-8'>
                  <div className='flex flex-col justify-start'>
                    <span className='text-xl font-semibold text-black self-start'>스케줄 테이블</span>
                    <span className='text-base font-normal text-gray-700 self-start'>제품별로 할당되어 있는 스케줄을 열람하고 관리(삭제)할 수 있습니다.</span> 
                  </div>

                  <div className='grid grid-cols-2 gap-4 pt-12'>
                  
                    {
                      devList.map((data, idx) => (
                        <div className="border border-gray-300 rounded-lg" key={idx}>
                          <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto flex flex-row p-6 border-b-2 borer-b-gray-300 justify-between">
                              <div className='flex flex-row justify-start rounded-lg gap-x-2'>
                                <div className='flex flex-row justify-center rounded-lg px-2 py-1 bg-indigo-100'>
                                  <Cog6ToothIcon className='w-8 h-8 text-indigo-700 self-center'/> 
                                </div>
                                <div className='flex flex-col justify-start '>
                                  <span className="text-base text-black font-semibold ">{data.deviceID}</span>
                                  <span className="text-base text-black font-semibold ">{data.deviceAlias}</span>
                                </div>
                              </div>
                              <div className='flex flex-row justify-end gap-x-2'>
                                {
                                  scheduleTable[data.devicePK].length === 0 ? null : (
                                    <>
                                      <ChevronLeftIcon className='w-7 h-7 text-black font-semibold self-center cursor-pointer' onClick={() => prevPagination(data.devicePK)} /> 
                                      <span className='text-base font-bold self-center'>{`${pageDict[`${data.devicePK}_page`]}/${Math.ceil(scheduleTable[data.devicePK].length/pageBasePoint)}`}</span>
                                      <ChevronRightIcon className='w-7 h-7 text-black font-semibold self-center cursor-pointer' onClick={() => nextPagiatnion(data.devicePK)}/> 
                                    </>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div className="flow-root py-2 overflow-x-scroll">
                            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                              <div className="inline-block min-w-full py-2 align-middle px-4 sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th scope="col" className="whitespace-nowrap py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">삭제</th>
                                      <th scope="col" className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900">구분</th>
                                      <th scope="col" className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900">콘텐츠</th>
                                      <th scope="col" className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900">송출 기간 </th>
                                      <th scope="col" className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900">송출 시간 </th>
                                      <th scope="col" className="whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900">등록일 </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {scheduleTable[data.devicePK].length === 0 ? (
                                      <td rowSpan={4} colSpan={6} className="w-full h-full py-24 text-sm text-gray-700 font-semibold self-center text-center">등록된 스케줄 없음</td>
                                    ) : (
                                      scheduleTable[data.devicePK].map((scheduleData, scheduleIdx) => (
                                        (scheduleIdx >= pageDict[`${data.devicePK}_startPage`] && scheduleIdx < pageDict[`${data.devicePK}_endPage`] ? (
                                          <tr key={scheduleIdx}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                              {/* marking */}
                                              <TrashIcon className='w-5 h-5 text-red-700 self-center font-bold text-sm cursor-pointer' onClick={() => scheduleDelete(scheduleData.schedulepk, scheduleData.type)} />
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.type === 'normal' ? '일반 스케줄' : '긴급 스케줄'}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.contentName}</td>
                                            {scheduleData.type === 'normal' ? (
                                              <>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.period}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.startTime}~{scheduleData.endTime}</td>
                                              </>
                                            ) : (
                                              <>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.period}</td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">시간 제한 없음</td>
                                              </>
                                            )}
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-700 font-semibold">{scheduleData.created}</td>
                                          </tr>
                                        ): null)
                                      ))
                                    )}

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }



                  </div>

                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default ScheduleTable